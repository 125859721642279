<template>
  <div class="TemplateList">
    <list
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="createData" />
      </template>
      <template #searchSlot>
        <v-input label="模板名称" v-model="searchParams.mouldName" :maxlength="100" />
        <v-select label="业务类型" v-model="searchParams.businessType" :options="businessTypeOps" />
        <v-select label="模板类型" v-model="searchParams.mouldType" :options="mouldTypeOps" />
        <v-datepicker label="创建时间" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.intimeFrom" :endTime.sync="searchParams.intimeTo" />
        <v-datepicker label="最近编辑时间" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.updateTimeFrom" :endTime.sync="searchParams.updateTimeTo" />
        <v-select2 label="操作人" v-model="searchParams.userId" v-bind="updateUserParams" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="updateData(scope.row)" />
        <v-button text="复制" type="text" permission="copy" @click="copyData(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, getUserListURL } from './api'
import { setBusinessTypeOps, setMouldTypeOps } from './map'

export default {
  name: 'TemplateList',
  data () {
    return {
      searchUrl: getListURL,
      businessTypeOps: setBusinessTypeOps(1),
      mouldTypeOps: setMouldTypeOps(1),
      maxDate: new Date(),
      updateUserParams: {
        searchUrl: getUserListURL,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      searchParams: {
        mouldName: '',
        businessType: undefined,
        mouldType: undefined,
        intimeFrom: '',
        intimeTo: '',
        updateTimeFrom: '',
        updateTimeTo: '',
        userId: ''
      },
      headers: [
        {
          prop: 'mouldCode',
          label: '模板编号'
        },
        {
          prop: 'mouldName',
          label: '模板名称'
        },
        {
          prop: 'businessTypeName',
          label: '业务类型'
        },
        {
          prop: 'mouldTypeName',
          label: '模板类型',
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'updateTime',
          label: '最近编辑时间'
        },
        {
          prop: 'updateUser',
          label: '操作人'
        }
      ]
    }
  },
  methods: {
    createData () {
      this.$router.push({
        name: 'templateForm'
      })
    },
    updateData (row) {
      this.$router.push({
        name: 'templateForm',
        query: {
          id: row.id
        }
      })
    },
    copyData (row) {
      this.$router.push({
        name: 'templateForm',
        query: {
          copyId: row.id
        }
      })
    }
  }
}
</script>
